.App {
  text-align: center;
  min-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  box-sizing: border-box;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .App {
    width: 1280px;
  }
}

.PlaceAutocomplete {
  padding: 8px;
  margin-top: 50px;
}

.Content {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.Main {
  display: flex;
  flex-direction: column;
  width: 680px;
  box-sizing: border-box;
  padding: 8px;
}

.Side {
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.Side .Map {
  flex: 1;
  height: 400px;
}

.Side .BuildingDataItem {
  height: 50px;
  box-sizing: border-box;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  width: 90%;
  position: relative;
}

.Side .BuildingDataItem .TooltipInfo {
  position: absolute;
  right: -2rem;
  cursor: pointer;
}

.Side .BuildingDataItem .TooltipInfo .TooltipText {
  font-size: 1rem;
}

.Side .BuildingDataItem:first-child {
  margin-top: 0;
}

.Side .BuildingDataItem .BuildingDataLabel {
  flex: 1;
  text-align: left;
  font-weight: bold;
}

.Side .BuildingDataItem .BuildingDataValue {
  margin-left: 5px;
}

.Side .BuildingDataZoneItem {
  box-sizing: border-box;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  width: 90%;
}

.Side .BuildingDataZoneItem .BuildingDataZoneSection {
  display: flex;
  align-items: center;
}

.Side .BuildingDataZoneItem .NeighborhoodId,
.Side .BuildingDataZoneItem .ZoneType,
.Side .BuildingDataZoneItem .NeighborhoodName,
.Side .BuildingDataZoneItem .City {
  flex: 1;
  text-align: left;
}

.Side .BuildingDataZoneItem .NeighborhoodId,
.Side .BuildingDataZoneItem .ZoneType {
  font-size: 1.1rem;
}

.Side .BuildingDataZoneItem .BuildingDataZone {
  display: flex;
  flex-direction: column;
  margin: 0.3rem 0;
}

.Side .BuildingDataZoneItem .BuildingDataZone section {
  display: flex;
  align-items: center;
}

.Side .BuildingDataZoneItem .BuildingDataLabel {
  flex: 1;
  text-align: left;
  font-weight: bold;
}

.Side .BuildingDataZoneItem .BuildingDataValue {
  margin-left: 5px;
}

.BuildingDataItem .BuildingDataValueButton {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #5387ff;
}
.BuildingDataItem .BuildingDataValueButton.Fail {
  color: #ff0000;
}

.Side .PlaceHolder .BuildingDataLabel {
  height: 1vh;
  background-color: #ccc;
}

.Side .PlaceHolder .BuildingDataValue {
  height: 1vh;
  width: 50px;
  background-color: #ccc;
}

.Businesses {
  box-sizing: border-box;
  padding: 10px;
  margin: 1vh 0;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  /* border: solid 1px #ddd; */
}

.Businesses thead {
  background-color: #ece9fd;
}

.Businesses .BusinessItem:nth-child(even) {
  background-color: #f6f6f6;
}

.Businesses .BusinessItem th {
  text-align: center;
  margin: 0;
  padding: 0;
  border: none;
}
.Businesses .BusinessItem td {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
}

.Businesses .BusinessItem {
  border-left: solid 1px #eeeeee;
  border-right: solid 1px #eeeeee;
  margin: 0;
  height: 30px;
}

.Businesses thead .BusinessItem:first-child {
  border-top: solid 1px #eeeeee;
}

.Businesses tbody .BusinessItem:last-child {
  border-bottom: solid 1px #eeeeee;
}

.Businesses .BusinessItem td {
  margin: 0;
  padding: 0;
}

.Businesses .BusinessItem th:first-child,
.Businesses .BusinessItem td:first-child {
  padding-left: 4px;
}

.Businesses .BusinessItem th:last-child,
.Businesses .BusinessItem td:last-child {
  padding-right: 4px;
}

.Businesses .BusinessItem tr .BusisnessName {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Businesses .BusinessItem .BusinessSiren {
  text-align: center;
}

.Businesses .BusinessItem .BusinessDistance {
  text-align: center;
}

.BuildNumber {
  color: #666;
  font-size: 12px;
}

.HistoricInfoWrapper {
  max-height: 20rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.HistoricInfoItem {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.HistoricInfoItemName {
  font-weight: bold;
}
